import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Section from "../../components/section";
import { H2 } from '../../css/stylecomp'
import Footer from "../../components/footer";
// <iframe src='http://davcon.mywire.org:8080/apps/valkrets_nyckelord' title='graph' frameborder='0' scrolling='no' style={{ "width": "100%", "height": "1000px" }}></iframe>

function ContactPage() {
    return (
        <Layout>
            <SEO
                title="Contact"
                keywords={[`data`, `science`, `consulting`]}
            />
            <div>
                <Section className="bg-very-lightest-grey border-solid border border-very-grey-light h-auto pt-8 pb-8 flex items-center" containerClass="">
                    <div className="flex flex-col lg:w-full text-center">
                        <H2 className="font-thin ">Debates in the Swedish Parliament. </H2>
                        <div className="text-grey text-lg font-thin leading-normal py-4">
                            This page shows the most popular phrases used by the respective parties across the voting districts.
            </div>
                    </div>
                </Section>
                <Section className="border-b border-solid border-very-grey-light" containerClass="py-10">


                </Section>
                <Footer />
            </div>
        </Layout>
    )
}

export default ContactPage;
